import { Divider, Group, Paper, Stack, Title } from '@mantine/core';
import React from 'react';
import { AnalysisRating } from './AnalysisRating';

export const AnalysisCard: React.FC<{
  title: string;
  rating?: number;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}> = ({ title, rating, children, icon }) => (
  <Paper shadow="md" radius="lg" p="xl">
    <Stack gap="sm">
      <Title order={5}>
        <Group>
          <Group gap={4}>
            {icon}
            {title}
          </Group>
          <AnalysisRating rating={rating} />
        </Group>
      </Title>
      <Divider mt="sm" mb="md" />
      <div>{children}</div>
    </Stack>
  </Paper>
);
