import { Loader, ThemeIcon, ThemeIconProps, Tooltip } from '@mantine/core';
import { IconAlertTriangle, IconFiles, IconLinkOff } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResumeStatus } from '../../models/Resume';

const generatingLoader = <Loader size="xs" variant="bars" />;

export const ResumeStatusIcon: React.FC<
  { status: ResumeStatus } & ThemeIconProps
> = ({ status, ...iconProps }) => {
  const { t } = useTranslation();

  switch (status) {
    case ResumeStatus.New:
      return (
        <Tooltip label={t(`application.status.${status}`)}>
          <ThemeIcon variant="outline" color="orange" {...iconProps}>
            <IconAlertTriangle />
          </ThemeIcon>
        </Tooltip>
      );
    case ResumeStatus.Preparing:
    case ResumeStatus.Generating:
    case ResumeStatus.CoverLetter:
      return generatingLoader;

    case ResumeStatus.Ok:
      return (
        <Tooltip label={t(`application.status.${status}`)}>
          <ThemeIcon color="teal" variant="outline" {...iconProps}>
            <IconFiles />
          </ThemeIcon>
        </Tooltip>
      );
    case ResumeStatus.Error:
      return (
        <Tooltip label={t(`application.status.${status}`)}>
          <ThemeIcon color="red" variant="outline" {...iconProps}>
            <IconAlertTriangle />
          </ThemeIcon>
        </Tooltip>
      );
    case ResumeStatus.InvalidURL:
      return (
        <Tooltip label={t(`application.status.${status}`)}>
          <ThemeIcon color="red" variant="outline" {...iconProps}>
            <IconLinkOff />
          </ThemeIcon>
        </Tooltip>
      );
  }
};
