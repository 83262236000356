export const AppleLocales: Record<string, string> = {
  en: 'en_US',
  de: 'de_DE',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
  pt: 'pt_PT',
};

export const getAppleLocale = (language: string) => {
  return AppleLocales[language.substring(0, 2)] ?? AppleLocales['en'];
};

export type AppleSignInText = 'sign-in' | 'continue' | 'sign-up';
