import {
  Alert,
  Button,
  Container,
  Stack,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { CreateResumeForm } from '../../components/CreateResume/CreateResumeForm';
import { GenerateResumeForm } from '../../components/GenerateResumeForm/GenerateResumeForm';
import { ReviewResumeForm } from '../../components/ReviewResumeForm/ReviewResumeForm';
import { useProfileQuery } from '../../models/ProfileQueries';
import { IResume } from '../../models/Resume';
import { getLogger } from '../../services/Logger';

const log = getLogger('ResumeWizardPage');

export const ResumeWizardPage: React.FC = () => {
  const step = Number(useParams().step ?? 0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resumeId = searchParams.get('resumeId');
  const jobAdUrl = searchParams.get('jobAd');
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { data: profile, isPending: profilePending } = useProfileQuery();

  if (jobAdUrl) {
    log.warn(`Job ad URL: ${jobAdUrl}`);
  }

  const errorAlert = (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title={t('create.wizard.errorTitle')}
      color="red"
    >
      {t('create.wizard.errorText')}
    </Alert>
  );

  const pageTitle: string = resumeId
    ? t('create.wizard.titleEdit')
    : t('create.wizard.titleCreate');

  if (
    !profilePending &&
    (!profile ||
      !profile.sections ||
      profile.sections.length === 0 ||
      profile.sections[0].title.trim() === '')
  ) {
    return (
      <Container>
        <Stack>
          <Title order={2} mb="xl">
            {pageTitle}
          </Title>
          <Alert
            icon={<IconAlertCircle size={16} />}
            title={t('create.wizard.profileEmptyTitle')}
            color="blue"
          >
            <Text>{t('create.wizard.profileEmptyText')}</Text>
          </Alert>
          <Button size="lg" component={Link} to={AppRouteURL.profile}>
            {t('create.wizard.profileEmptyButton')}
          </Button>
        </Stack>
      </Container>
    );
  }

  const onResumeCreated = (createdResumeId: IResume['id']) => {
    navigate(`${AppRouteURL.resumes.create}/1?resumeId=${createdResumeId}`);
  };

  const onError = (err: unknown) => {
    log.error(err);
  };

  return (
    <Container p={isSmallScreen ? 0 : 'md'}>
      <Bowl
        title={`${pageTitle} - ${
          t('create.wizard.step', { step: step + 1 }) as string
        }`}
      />

      <Stack>
        <Title order={2} mb="xl">
          {pageTitle}
        </Title>
        <Stepper
          active={step}
          onStepClick={(stepIndex) =>
            navigate(
              `${AppRouteURL.resumes.create}/${stepIndex}${
                resumeId ? `?resumeId=${resumeId}` : ''
              }`,
            )
          }
          allowNextStepsSelect={false}
          size={isSmallScreen ? 'xs' : 'md'}
        >
          <Stepper.Step
            label={!isSmallScreen && t('create.wizard.firstStep')}
            description={
              !isSmallScreen && t('create.wizard.firstStepDescription')
            }
          >
            <CreateResumeForm
              onComplete={onResumeCreated}
              onError={onError}
              resumeId={resumeId ?? undefined}
            />
          </Stepper.Step>
          <Stepper.Step
            label={!isSmallScreen && t('create.wizard.secondStep')}
            description={
              !isSmallScreen && t('create.wizard.secondStepDescription')
            }
          >
            {resumeId ? (
              <ReviewResumeForm
                resumeId={resumeId}
                onBack={() =>
                  navigate(
                    `${AppRouteURL.resumes.create}/0?resumeId=${resumeId}`,
                  )
                }
                onComplete={() =>
                  navigate(
                    `${AppRouteURL.resumes.create}/2?resumeId=${resumeId}`,
                  )
                }
                onError={onError}
              />
            ) : (
              errorAlert
            )}
          </Stepper.Step>
          <Stepper.Step
            label={!isSmallScreen && t('create.wizard.finalStep')}
            description={
              !isSmallScreen && t('create.wizard.finalStepDescription')
            }
          >
            {resumeId ? (
              <GenerateResumeForm
                onBack={() =>
                  navigate(
                    `${AppRouteURL.resumes.create}/1?resumeId=${resumeId}`,
                  )
                }
                resumeId={resumeId}
              />
            ) : (
              errorAlert
            )}
          </Stepper.Step>
          <Stepper.Completed>
            {t('create.wizard.completedLabel')}
          </Stepper.Completed>
        </Stepper>
      </Stack>
    </Container>
  );
};
