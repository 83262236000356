import {
  Alert,
  Anchor,
  Button,
  Center,
  Container,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { useUserQuery } from '../../models/User';
import { getLogger } from '../../services/Logger';

const log = getLogger('DeleteAccountPopup');

export const DeleteAccountPopup: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery('(max-width: 50em)');
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useUserQuery();
  const userEmail = user?.email;
  const { signout } = useAuth();
  const queryClient = useQueryClient();

  const closeModal = () => {
    close();
    navigate(window.location.pathname + window.location.search, {
      replace: true,
    });
  };

  useEffect(() => {
    if (location.hash === '#delete-account') {
      open();
    }
  }, [location]);

  useEffect(() => {
    setEmailValid(email === userEmail);
  }, [email, userEmail]);

  const deleteAccount = () => {
    setDeleteError(null);

    Api.ky
      .delete(ApiRoutes.Me, { json: { email } })
      .then(() => signout())
      .then(() => queryClient.resetQueries())
      .then(() => navigate(AppRouteURL.login))
      .catch((error) => {
        log.error(error);
        if (error instanceof Error || typeof error === 'string') {
          setDeleteError(error.toString());
        }
        closeModal();
      });
  };

  const errorComponent = deleteError ? (
    <Alert
      title={t('translation:account.deleteErrorTitle')}
      color="red"
      icon={<IconTrash />}
    >
      <Stack>
        <Text>{t('translation:account.deleteErrorText')}</Text>
        <Text>
          <i>{deleteError}</i>
        </Text>
      </Stack>
    </Alert>
  ) : null;

  return (
    <>
      <Modal
        opened={opened}
        onClose={closeModal}
        title={<Title order={4}>{t('account.deleteAccountTitle')}</Title>}
        fullScreen={isMobile}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <Container>
          <Stack gap="xl">
            <Text>{t('account.deleteAccountText')}</Text>
            <TextInput
              label={t('account.enterEmailToConfirm')}
              placeholder="example@example.com"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
              required
            />
            <Button
              size="xl"
              variant="outline"
              color="red"
              leftSection={<IconTrash />}
              onClick={deleteAccount}
              disabled={!emailValid}
            >
              {t('account.confirmDelete')}
            </Button>
            <Center>
              <Anchor onClick={closeModal}>{t('account.cancelDelete')}</Anchor>
            </Center>
          </Stack>
        </Container>
      </Modal>
      <Stack>
        {errorComponent}
        <Button color="grey" onClick={open}>
          {t('account.deleteAccountButton')}
        </Button>
      </Stack>
    </>
  );
};
