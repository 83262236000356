import {
  Box,
  Button,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import {
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconTrash,
} from '@tabler/icons-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile, IUserProfileSection } from '../../models/Profile';
import { useProfileMutation } from '../../models/ProfileQueries';

type TimelineProfileValues = IUserProfileSection;

const TimelineSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  company: Yup.string().required('Company is required'),
  from: Yup.string().required('Start date is required'),
  to: Yup.string().optional(),
  description: Yup.string().optional(),
});

const FormLabel: React.FC<{ label: string; tooltip: string }> = ({
  label,
  tooltip,
}) => (
  <Tooltip
    label={tooltip}
    multiline
    w={240}
    events={{ hover: true, focus: true, touch: true }}
    withArrow
    openDelay={500}
  >
    <Text td="dotted underline" size="sm">
      {label}
    </Text>
  </Tooltip>
);

export const TimelineModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  section: IUserProfileSection | null;
  sections: IUserProfileSection[] | null;
  profile?: IUserProfile | null;
}> = ({ opened, onClose, section, sections = [], profile }) => {
  const { t } = useTranslation();
  const updateProfileMutation = useProfileMutation();

  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const initialValues = {
    id: section?.id,
    title: section?.title ?? '',
    company: section?.company ?? '',
    from: section?.from ?? '',
    to: section?.to ?? '',
    description: section?.description ?? '',
  } as TimelineProfileValues;

  const handleSubmit = (
    values: TimelineProfileValues,
    actions: FormikHelpers<TimelineProfileValues>,
  ) => {
    const updatedSections = values.id
      ? sections?.map((s) => (s.id === values.id ? values : s))
      : [...(sections || []), values];

    updateProfileMutation
      .mutateAsync({ ...profile, sections: updatedSections })
      .then(() => {
        actions.resetForm({ values });
        onClose();
      })
      .catch((error) => actions.setStatus(error))
      .finally(() => actions.setSubmitting(false));
  };

  const handleDelete = () => {
    openConfirmModal({
      title: t('profile.deleteTimelineEntryTitle'),
      centered: true,
      children: (
        <Text size="sm">
          {section?.title
            ? t('profile.deleteTimelineEntryText', {
                entry: section.title,
              })
            : t('profile.deleteTimelineEntryTextGeneric')}
        </Text>
      ),
      labels: {
        confirm: t('labels.delete'),
        cancel: t('labels.cancel'),
      },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (section) {
          const updatedSections =
            sections?.filter((s) => s.id !== section.id) || [];
          updateProfileMutation.mutate({
            ...profile,
            sections: updatedSections,
          });
          onClose();
        }
      },
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text size="lg" fw="bolder">
          {t('profile.editTimelineEntry')}
        </Text>
      }
      size="lg"
      withCloseButton={false}
      fullScreen={isSmallScreen}
    >
      <Box p={{ base: 0, md: 'md' }}>
        <Formik<TimelineProfileValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={TimelineSchema}
        >
          {(formikProps) => (
            <Form>
              <Stack>
                <Field
                  as={TextInput}
                  label={
                    <FormLabel
                      label={t('timeline.title')}
                      tooltip={t('timeline.titleTooltip')}
                    />
                  }
                  placeholder={t('timeline.titlePlaceholder')}
                  name="title"
                  error={formikProps.touched.title && formikProps.errors.title}
                />
                <Field
                  as={TextInput}
                  label={
                    <FormLabel
                      label={t('timeline.company')}
                      tooltip={t('timeline.companyTooltip')}
                    />
                  }
                  placeholder={t('timeline.companyPlaceholder')}
                  name="company"
                  error={
                    formikProps.touched.company && formikProps.errors.company
                  }
                  icon={<IconBuildingSkyscraper />}
                />
                <Flex gap={16}>
                  <Field
                    as={TextInput}
                    label={
                      <FormLabel
                        label={t('timeline.startDate')}
                        tooltip={t('timeline.startDateTooltip')}
                      />
                    }
                    placeholder={t('timeline.startDatePlaceholder')}
                    name="from"
                    error={formikProps.touched.from && formikProps.errors.from}
                    icon={<IconCalendar />}
                  />
                  <Field
                    as={TextInput}
                    label={
                      <FormLabel
                        label={t('timeline.endDate')}
                        tooltip={t('timeline.endDateTooltip')}
                      />
                    }
                    placeholder={t('timeline.endDatePlaceholder')}
                    name="to"
                    error={formikProps.touched.to && formikProps.errors.to}
                    icon={<IconCalendar />}
                  />
                </Flex>
                <Field
                  as={Textarea}
                  label={
                    <FormLabel
                      label={t('timeline.description')}
                      tooltip={t('timeline.descriptionTooltip')}
                    />
                  }
                  placeholder={t('timeline.descriptionPlaceholder')}
                  name="description"
                  error={
                    formikProps.touched.description &&
                    formikProps.errors.description
                  }
                  minRows={10}
                  autosize
                />
              </Stack>
              <Group justify="space-between" mt="xl">
                {section?.id && (
                  <Button
                    leftSection={<IconTrash />}
                    variant="subtle"
                    size="sm"
                    onClick={handleDelete}
                    disabled={formikProps.isSubmitting}
                    color="red"
                  >
                    {t('labels.delete')}
                  </Button>
                )}
                <Group justify="flex-end">
                  <Button
                    variant="light"
                    size="sm"
                    onClick={onClose}
                    disabled={formikProps.isSubmitting}
                  >
                    {t('labels.close')}
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="filled"
                    leftSection={<IconCheck />}
                    loading={formikProps.isSubmitting}
                    disabled={
                      !formikProps.isValid ||
                      formikProps.isSubmitting ||
                      !formikProps.dirty
                    }
                  >
                    {formikProps.isSubmitting
                      ? t('labels.saving')
                      : t('labels.save')}
                  </Button>
                </Group>
              </Group>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
