import { Container, Stack, Text, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { AppRouteURL } from '../AppRouteURL';
import { Bowl } from '../components/Bowl/Bowl';
import { PRODUCT_NAME } from '../Constants';

const UUID_V4_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const VerifyEmailPage: React.FC = () => {
  const [params] = useSearchParams();
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  let initialized = false;
  const { t } = useTranslation();

  const id = params.get('id');
  const token = params.get('token');

  if (!(id && UUID_V4_REGEX.test(id) && token)) {
    return <div>{t('verify.invalidToken')}</div>;
  }

  const onSuccess = () => {
    return new Promise<void>((resolve) => {
      showNotification({
        title: t('verify.successTitle'),
        message: t('verify.successMessage'),
      });
      setTimeout(() => {
        navigate(AppRouteURL.home);
        navigate(0);
        resolve();
      }, 2000);
    });
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      Api.ky
        .post(ApiRoutes.VerifyEmail, {
          json: {
            id,
            token,
          },
        })
        .then(() => onSuccess())
        .catch(() => setError(t('verify.errorMessage')));
    }
  }, []);

  return (
    <Container>
      <Bowl title={t('verify.pageTitle')} />
      <Stack p="xl" m="xl" gap="lg" align="center">
        <Title order={2} m="xl">
          {t('verify.verifyingMessage', { brand: PRODUCT_NAME })}
        </Title>
        <Text size="xl" m="xl">
          {error || t('verify.standBy')}
        </Text>
      </Stack>
    </Container>
  );
};
