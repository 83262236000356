import { Container, Divider, Group, Paper, Stack, Text } from '@mantine/core';
import {
  IconAbc,
  IconAlpha,
  IconBrandGithub,
  IconBrandLinkedin,
  IconBrowser,
  IconHome,
  IconLink,
  IconMail,
  IconOmega,
  IconPhone,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WhatIsThis from '../../components/WhatIsThis/WhatIsThis';
import { useProfileQuery } from '../../models/ProfileQueries';
import { BasicInfoModal } from './BasicInfoModal';
import { LinksModal } from './LinksModal';
import { ProfileLanguageSection } from './ProfileLanguageSection';
import type { UserProfileValues } from './ProfilePage';
import { TitleWithEditButton } from './TitleWithEditButton';

const ProfileDisplayField: React.FC<{
  name: keyof UserProfileValues;
  value?: string;
  icon: React.ReactNode;
}> = ({ name, value, icon }) => {
  const { t } = useTranslation();
  return (
    <Stack gap={0}>
      <Group gap={4}>
        {icon}
        <Text fz="sm" c="dimmed">
          {t(`contact.${name}`)}
        </Text>
      </Group>
      <Text ml={18} c={value ? '' : 'dimmed'} fs={value ? '' : 'italic'}>
        {value || '—'}
      </Text>
    </Stack>
  );
};

export const BasicInfoTab: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { data: profile } = useProfileQuery();

  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [linksModalOpen, setLinksModalOpen] = useState(false);

  return (
    <Container my="lg" pb="xl" px={0}>
      <Stack align="left">
        <WhatIsThis message={t('profile.basicInfoHelp')} />

        <Stack gap="xl">
          <Paper
            p="sm"
            shadow="sm"
            onClick={() => setBasicModalOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <TitleWithEditButton
              disabled={disabled}
              icon={<IconAbc />}
              title={t('profile.basicInfoTitle')}
              onClick={() => setBasicModalOpen(true)}
            />
            <Divider mt="xs" mb="lg" />

            <Stack>
              <ProfileDisplayField
                value={profile?.firstName}
                name="firstName"
                icon={<IconAlpha size={14} />}
              />
              <ProfileDisplayField
                name="lastName"
                value={profile?.lastName}
                icon={<IconOmega size={14} />}
              />
              <ProfileDisplayField
                name="phone"
                value={profile?.phone}
                icon={<IconPhone size={14} />}
              />
              <ProfileDisplayField
                name="address"
                value={profile?.address}
                icon={<IconHome size={14} />}
              />
              <ProfileDisplayField
                name="displayEmail"
                value={profile?.displayEmail}
                icon={<IconMail size={14} />}
              />
            </Stack>
          </Paper>

          <Paper
            p="sm"
            shadow="sm"
            onClick={() => setLinksModalOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <TitleWithEditButton
              icon={<IconLink />}
              title={t('profile.links')}
              onClick={() => setLinksModalOpen(true)}
              disabled={disabled}
            />

            <Divider mt="xs" mb="lg" />

            <Stack>
              <ProfileDisplayField
                name="linkedIn"
                icon={<IconBrandLinkedin size={14} />}
                value={profile?.linkedIn}
              />
              <ProfileDisplayField
                name="github"
                icon={<IconBrandGithub size={14} />}
                value={profile?.github}
              />
              <ProfileDisplayField
                name="website"
                icon={<IconBrowser size={14} />}
                value={profile?.website}
              />
            </Stack>
          </Paper>

          <ProfileLanguageSection
            languages={profile?.languages}
            disabled={disabled}
          />
        </Stack>
      </Stack>
      <BasicInfoModal
        opened={basicModalOpen}
        onClose={() => setBasicModalOpen(false)}
      />
      <LinksModal
        opened={linksModalOpen}
        onClose={() => setLinksModalOpen(false)}
      />
    </Container>
  );
};
