import { Group, rem, Stack, Text, Title } from '@mantine/core';
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from '@mantine/dropzone';
import {
  IconAlertCircle,
  IconFileUpload,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logger } from 'tslog';

const logger = new Logger({ name: 'ResumeDropzone' });

export const ResumeDropzone: React.FC<{
  onFileSelected: (file: FileWithPath) => Promise<void>;
  withWarning?: boolean;
}> = ({ onFileSelected, withWarning = false }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Title order={4}>{t('analyze.dropzoneTitle')}</Title>
      <Text>{t('analyze.description')}</Text>
      {withWarning && (
        <Group gap="xs">
          <IconAlertCircle stroke={1} />

          <Text>
            <i>{t('analyze.reuploadWarning')}</i>
          </Text>
        </Group>
      )}
      <Dropzone
        onDrop={(files) => onFileSelected(files[0])}
        onReject={(files) => logger.error('rejected files', files)}
        maxSize={5 * 1024 ** 2}
        accept={[...PDF_MIME_TYPE]}
      >
        <Group
          justify="center"
          gap="xl"
          style={{ minHeight: 220, pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconUpload size={50} stroke={1.5} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={50} stroke={1.5} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileUpload size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline ta="center">
              {t('analyze.dragAndDrop')}
            </Text>
            <Text size="sm" c="dimmed" inline mt={rem(16)} ta="center">
              {t('analyze.importFormat')}
            </Text>
          </div>
        </Group>
      </Dropzone>
    </Stack>
  );
};
