import { Anchor, Center, Container, Stack, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { PRODUCT_NAME } from '../../Constants';
import { useUserQuery } from '../../models/User';
import { getLogger } from '../../services/Logger';

const log = getLogger('CheckoutSuccessPage');

export const CheckoutSuccessPage: React.FC = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { refetch } = useUserQuery();

  const sessionId = params.get('sessionId');

  useEffect(() => {
    refetch().catch((e) => log.error(e));
  }, []);

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Bowl title={t('translation:checkout.successPageTitle')} />

        <Center>
          <Title order={2}>{t('translation:checkout.successPageTitle')}</Title>
        </Center>

        <Center>
          <Text fz="xl">{t('checkout.thankYou')}</Text>
        </Center>

        <Center>
          <Anchor component={Link} to={AppRouteURL.home}>
            {t('checkout.homeLink', { brand: PRODUCT_NAME })}
          </Anchor>
        </Center>

        <Text fz="xs" fw={200} mt="xl">
          {t('checkout.sessionId', { sessionId })}
        </Text>
      </Stack>
    </Container>
  );
};
