import { Badge, Box, Group, Text, ThemeIcon } from '@mantine/core';
import { IconAlertCircle, IconCrown } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React from 'react';
import { SubscriptionType, useUserQuery } from '../../models/User';

const SubscriptionInfo: React.FC = () => {
  const { data: user } = useUserQuery();

  const subscriptionType = dayjs(user?.subscriptionExpiresAt).isAfter(dayjs())
    ? SubscriptionType.PRO
    : SubscriptionType.FREE;

  const isExpired =
    subscriptionType === SubscriptionType.FREE &&
    user?.subscriptionExpiresAt !== null;

  return (
    <Box p="lg">
      <Group align="center" mb="md">
        <Group align="center">
          <ThemeIcon
            size="xl"
            radius="md"
            variant="gradient"
            gradient={{
              from:
                subscriptionType === SubscriptionType.PRO ? 'yellow' : 'gray',
              to: subscriptionType === SubscriptionType.PRO ? 'orange' : 'dark',
            }}
            style={{ boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)' }}
          >
            {subscriptionType === SubscriptionType.PRO ? (
              <IconCrown size={28} stroke={1.5} />
            ) : (
              <IconAlertCircle size={28} stroke={1.5} />
            )}
          </ThemeIcon>
          <Text size="xl" fw={700}>
            {t('subscription.currentPlan')}
          </Text>
        </Group>
        <Badge
          gradient={{ from: 'teal', to: 'lime', deg: 105 }}
          variant="gradient"
          size="lg"
          radius="md"
          style={{ padding: '4px 12px' }}
        >
          {t(`subscription.${subscriptionType}`)}
        </Badge>
      </Group>
      <Text c="dimmed" mb="sm">
        {t(`subscription.${subscriptionType}Description`)}
      </Text>
      {user && subscriptionType === SubscriptionType.PRO && (
        <Box mt="md">
          <Text c="dimmed">
            {t('subscription.proEndsAt', {
              expiry: dayjs(user.subscriptionExpiresAt).format('L'),
            })}
          </Text>
        </Box>
      )}
      {isExpired && (
        <Box mt="md">
          <Text c="red" fw={700}>
            {t('subscription.expiredOn', {
              expiry: dayjs(user?.subscriptionExpiresAt).format('L'),
            })}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionInfo;
