import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IUser, QUERY_KEY_USER } from '../models/User';

type UpdateUserFields = Partial<IUser>;

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updateFields: UpdateUserFields) =>
      Api.ky.put(ApiRoutes.Me, { json: updateFields }).json(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_USER }),
  });
};

export const useFinishTourMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tour: string) =>
      Api.ky.post(ApiRoutes.Tours, { json: { tour } }).json(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_USER }),
  });
};
