import {
  Anchor,
  Center,
  Container,
  Group,
  List,
  rem,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import {
  Dropzone,
  FileWithPath,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import {
  IconCircleCheck,
  IconCircleDashed,
  IconFileUpload,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Logger } from 'tslog';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { QUERY_KEY_PROFILE } from '../../models/ProfileQueries';
import Tracking from '../../services/Tracking';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = new Logger({ name: 'ImportProfilePage' });

export const ImportProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: (file: FileWithPath) =>
      Api.upload(ApiRoutes.ProfileImport, file).text(),
  });
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const onFileSelected = async (file: FileWithPath) => {
    Tracking.event({ event: 'profile_import' });
    try {
      showNotification({
        id: 'profile-import',
        title: t('translation:import.importingTitle'),
        message: t('translation:import.importingMessage'),
        loading: true,
        autoClose: 10000,
      });

      const data = await mutation.mutateAsync(file);
      logger.debug(`imported ${data}`);

      await queryClient.invalidateQueries({ queryKey: QUERY_KEY_PROFILE });

      navigate(AppRouteURL.profileTabBasic);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Container>
      <Title order={2}>{t('translation:import.pageTitle')}</Title>
      <Center>
        <Stack m="md">
          <List
            size="xl"
            my="md"
            spacing="lg"
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <IconCircleCheck size="1rem" />
              </ThemeIcon>
            }
          >
            <List.Item>{t('import.helpLine1')}</List.Item>
            <List.Item>
              {t('import.helpLine2')} (
              <Anchor
                href="https://www.linkedin.com/help/linkedin/answer/a541960/save-a-profile-as-a-pdf"
                target="_blank"
              >
                {t('import.showMeHow')}
              </Anchor>
              )
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon color="red" size={24} radius="xl">
                  <IconCircleDashed size="1rem" />
                </ThemeIcon>
              }
            >
              {t('translation:import.importWarningText')}
            </List.Item>
          </List>

          <Dropzone
            onDrop={(files) => onFileSelected(files[0])}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={5 * 1024 ** 2}
            accept={[...MS_WORD_MIME_TYPE, ...PDF_MIME_TYPE]}
          >
            <Group
              justify="center"
              gap="xl"
              style={{ minHeight: 220, pointerEvents: 'none' }}
            >
              <Dropzone.Accept>
                <IconUpload size={50} stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={50} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconFileUpload size={50} stroke={1.5} />
              </Dropzone.Idle>

              <div>
                <Text size="xl" inline ta="center">
                  {t('translation:import.dragAndDrop')}
                </Text>
                <Text size="sm" color="dimmed" inline mt={rem(16)} ta="center">
                  {t('translation:import.importFormat')}
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Stack>
      </Center>
    </Container>
  );
};
