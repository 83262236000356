import {
  ActionIcon,
  Anchor,
  Group,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { DeleteResumeButton } from '../../components/DeleteResumeButton/DeleteResumeButton';
import { IResume, ResumeStatus, splitTitleText } from '../../models/Resume';
import { ResumeStatusIcon } from './ResumeStatusIcon';
import { ResumeTitleText } from './ResumeTitleText';

const viewUrl = (id: string) => AppRouteURL.resumes.view + '/' + id;
const createUrl = (id: string) =>
  AppRouteURL.resumes.create + '/0?resumeId=' + id;
const generatingUrl = (id: string) =>
  AppRouteURL.resumes.create + '/2?resumeId=' + id;
const resumeLink = (id: string, status: ResumeStatus) => {
  switch (status) {
    case ResumeStatus.New:
    case ResumeStatus.Error:
    case ResumeStatus.InvalidURL:
      return createUrl(id);
    case ResumeStatus.Preparing:
    case ResumeStatus.Generating:
    case ResumeStatus.CoverLetter:
      return generatingUrl(id);
    case ResumeStatus.Ok:
      return viewUrl(id);
  }
};

export const ResumeTableRow: React.FC<{
  resume: IResume;
}> = ({ resume }) => {
  const { t } = useTranslation();

  const { label, description } = splitTitleText(resume);

  return (
    <Table.Tr>
      <Table.Td>
        <Anchor
          component={Link}
          to={resumeLink(resume.id, resume.status)}
          variant="text"
          c="var(--mantine-color-text)"
          underline="never"
        >
          <Group gap="xs">
            <ResumeStatusIcon status={resume.status} size="2em" />
            <Stack gap={0}>
              <Title order={5}>
                {label || <ResumeTitleText resume={resume} />}
              </Title>
              <Text c="dimmed" fz="sm">
                {description || t('application.status.error')}
              </Text>
            </Stack>
          </Group>
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Tooltip label={dayjs(resume.createdAt).format('LLLL')}>
          <Text>{dayjs(resume.createdAt).fromNow()}</Text>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <Group gap="xs" miw={72}>
          <Tooltip label={t('applications.viewApplication')}>
            <ActionIcon
              component={Link}
              to={viewUrl(resume.id)}
              variant="subtle"
            >
              <IconEye />
            </ActionIcon>
          </Tooltip>
          <DeleteResumeButton resume={resume} />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};
