import * as Sentry from '@sentry/react';
import { IUser } from '../models/User';
import { hashString } from '../utils';

window.dataLayer = window.dataLayer || [];

window.gtag =
  window.gtag ||
  function <Command extends keyof Gtag.GtagCommands>(
    command: Command,
    ...args: Gtag.GtagCommands[Command]
  ) {
    window.dataLayer.push(arguments);
  };

class Tracking {
  static setUser(user: IUser, ip?: string) {
    Sentry.setUser({
      id: user.id,
      username: user.username,
      ip_address: ip || '{{auto}}',
    });

    gtag('set', {
      user_id: `${user.id}`,
    });

    hashString(user.email)
      .then((emailHash) => {
        Tracking.setUserData({ sha256_email_address: emailHash });
      })
      .catch((err) => {
        console.error('Error while hashing email:', err);
      });
  }

  static clearUser() {
    Sentry.setUser(null);
    gtag('set', {
      user_id: null,
    });
    Tracking.setUserData({ sha256_email_address: null });
  }

  static setUserData(userData: Record<string, unknown>) {
    gtag('set', {
      user_data: userData,
    });
  }

  static trackBeginCheckout(itemId: string) {
    return new Promise<void>((resolve) => {
      let eventSent = false;

      const fallbackTimeout = setTimeout(() => {
        if (!eventSent) {
          eventSent = true;
          resolve();
        }
      }, 500);

      gtag('event', 'begin_checkout', {
        event_callback: function () {
          if (!eventSent) {
            eventSent = true;
            clearTimeout(fallbackTimeout);
            resolve();
          }
        },
        event_timeout: 300,
        items: [{ item_id: itemId }],
      });
    });
  }

  static trackSignup({
    email,
    method,
  }: {
    email?: string;
    method: 'email' | 'google' | 'apple';
  }) {
    if (!email) {
      gtag('event', 'sign_up', { method });
      return Promise.resolve();
    }

    return hashString(email)
      .then((emailHash) => {
        gtag('set', { sha256_email_address: emailHash });
        gtag('event', 'sign_up', { method });
      })
      .catch((err) => {
        console.error('Error while hashing email:', err);
      });
  }

  static event({ event }: { event: string }) {
    gtag('event', event);
  }

  static trackLogin({ method }: { method: string }) {
    gtag('event', 'login', { method });
  }

  static setConsent(consentStatus: Gtag.ConsentParams) {
    gtag('consent', 'update', consentStatus);
  }

  static consentAll() {
    Tracking.setConsent({
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });
  }

  static denyAll() {
    Tracking.setConsent({
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });
  }
}

export default Tracking;
