import { AppShell, Box, Burger, Group, useMantineTheme } from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import React, { useMemo } from 'react';
import { Logo } from '../Logo/Logo';
import { UserMenu } from '../UserMenu/UserMenu';
import { LanguageMenu } from './LanguageMenu';

export const AppHeader: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const theme = useMantineTheme();
  const { width, height } = useViewportSize();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const safeAreaTop = useMemo(
    () =>
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--sat'),
      ),
    [width, height],
  );

  const marginTop = safeAreaTop > 0 ? 8 : 0;

  return (
    <AppShell.Header>
      <Box mt={marginTop} w="100%" />
      <Group justify="space-between" bottom={0}>
        <Group gap="xs">
          {!smallScreen ? (
            <Box m={12}>
              <Logo type="Banner" h={40} w="auto" />
            </Box>
          ) : (
            <Box mt={12} mb={12} ml={12} mr={0}>
              <Logo type="Pictogram" h={40} />
            </Box>
          )}

          <Burger
            opened={open}
            onClick={() => setOpen(!open)}
            hiddenFrom="sm"
            size="sm"
            mx={8}
          />
        </Group>

        <Group gap={4}>
          <LanguageMenu minViewPortSize="xs" />
          <Box mr="sm" id="app-header-user-menu">
            <UserMenu />
          </Box>
        </Group>
      </Group>
    </AppShell.Header>
  );
};
