import { Button, Container, Image, Space, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { getLogger } from '../../services/Logger';

import errorImage from './error-image.webp';

const log = getLogger('ErrorPage');

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const error = useRouteError();

  useEffect(() => {
    if (error) {
      log.error(
        `Sending error to Sentry: ${error instanceof Error ? error.message : 'unknown'}`,
      );
      Sentry.captureException(error);
    }
  }, [error]);

  const handleGoHome = () => {
    navigate(AppRouteURL.home);
  };

  return (
    <Container style={{ textAlign: 'center', padding: '2rem' }}>
      <Image src={errorImage} alt="Error" style={{ margin: '0 auto' }} />

      <Space h="md" />
      <Title order={2} style={{ color: '#FF6347' }}>
        {t('error.title')}
      </Title>
      <Text size="lg" c="dimmed">
        {t('error.description')}
      </Text>
      <Space h="md" />
      <Button variant="outline" onClick={handleGoHome}>
        {t('error.homeButton')}
      </Button>
    </Container>
  );
};

export default ErrorPage;
