import React, { createContext, useContext, useEffect, useState } from 'react';

import Api, { ILoginPayload } from './Api';
import { ApiRoutes } from './ApiRoutes';
import type { IUser } from './models/User';
import { getLogger } from './services/Logger';
import Tracking from './services/Tracking';

const CHECK_REFRESH_INTERVAL = 5000;
const CHECK_REFRESH_THRESHOLD = 10000;

interface IUserCredentials {
  email: string;
  password: string;
}

export interface IToken {
  expiresIn: number;
  accessToken: string;
}

interface IAuthContext {
  user?: IUser | null;
  // token?: IToken | null;
  loading: boolean;
  signin: (credentials: IUserCredentials) => Promise<IUser>;
  signup: (credentials: IUserCredentials) => Promise<IUser>;
  signout: () => Promise<void>;
  resetPassword: (email: string) => Promise<unknown>;
}

const log = getLogger('Auth');

const authContext = createContext<IAuthContext>({} as IAuthContext);

let initialized = false;

export const ProvideAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

let timer: ReturnType<typeof setTimeout> | undefined = undefined;

const useProvideAuth = (): IAuthContext => {
  const [token, setToken] = useState<IToken | null>();
  const [user, setUser] = useState<IUser | null>();
  const [loading, setLoading] = useState(true);

  const performRefresh = async () => {
    try {
      setLoading(true);
      return await refresh();
    } catch (error) {
      clearToken();
      log.info('Refresh failed, logging out.');
    } finally {
      setLoading(false);
    }
  };

  const calcNextExpiry = (token?: IToken | null) =>
    new Date().getTime() +
    Math.max(0, (token?.expiresIn ?? 0) * 1000 - CHECK_REFRESH_THRESHOLD);

  let nextExpiry: number | undefined = token
    ? calcNextExpiry(token)
    : undefined;

  const checkRefresh = async () => {
    if (nextExpiry !== undefined && Date.now() > nextExpiry) {
      nextExpiry = undefined;
      await performRefresh();
    }
  };

  const startRefresh = () => {
    if (timer !== undefined) {
      log.warn('Overriding existing refresh timer');
      clearInterval(timer);
    }
    log.info('Starting refresh timer');
    Api.enableRefresh();
    timer = setInterval(() => checkRefresh(), CHECK_REFRESH_INTERVAL);
  };

  const stopRefresh = () => {
    if (timer !== undefined) {
      log.info('Stopping refresh timer');
      clearInterval(timer);
      Api.disableRefresh();
      timer = undefined;
    }
  };

  const clearToken = () => {
    stopRefresh();
    Api.clearToken();
    setToken(null);
    setUser(null);
    Tracking.clearUser();
  };

  const handleLoginPayload = ({ token, user }: ILoginPayload) => {
    Api.setToken(token);
    setToken(token);
    nextExpiry = calcNextExpiry(token);

    setUser(user);

    Tracking.setUser(user);

    if (timer === undefined) {
      startRefresh();
    }

    return user;
  };

  const refresh = () =>
    Api.kyCredentials
      .post(ApiRoutes.Refresh)
      .json<ILoginPayload>()
      .then(handleLoginPayload);

  const signin = (credentials: IUserCredentials): Promise<IUser> =>
    Api.kyCredentials
      .post(ApiRoutes.Login, { json: credentials })
      .json<ILoginPayload>()
      .then(handleLoginPayload);

  const signup = (credentials: IUserCredentials): Promise<IUser> =>
    Api.kyCredentials
      .post(ApiRoutes.Signup, { json: credentials })
      .json<ILoginPayload>()
      .then(handleLoginPayload);

  const signout = (): Promise<void> =>
    Api.kyCredentials
      .get(ApiRoutes.Logout)
      .json()
      .then(() => clearToken());

  const resetPassword = async (email: string) =>
    Api.ky.post(ApiRoutes.ResetPassword, { json: { email } }).json();

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      void performRefresh();
    }
  }, []);

  return {
    user,
    loading,
    signin,
    signup,
    signout,
    resetPassword,
  };
};
