import { Anchor, Container, Group, Stack, Title } from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { TermlyEmbed } from '../../components/TermlyEmbed/TermlyEmbed';
import { PRODUCT_NAME } from '../../Constants';

export const LegalDocumentType = {
  privacy: {
    title: 'Privacy Policy',
    termlyId: 'afac7b95-fa1f-48a0-90d5-e645f200927b',
  },
  terms: {
    title: 'Terms of Service',
    termlyId: 'a9739080-9fd0-4556-a8a4-c5506cb34ef5',
  },
  cookie: {
    title: 'Cookie Policy',
    termlyId: '9964877f-0410-4d77-8872-10504328991e',
  },
  disclaimer: {
    title: 'Disclaimer',
    termlyId: '2b990058-e96f-461c-b493-001336ca1f91',
  },
  acceptableUse: {
    title: 'Acceptable Use Policy',
    termlyId: '6ab34f16-ef80-48b5-b506-e524fc1a67cb',
  },
};

const LegalDocumentPage: React.FC<{
  type: keyof typeof LegalDocumentType;
}> = ({ type }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Container size="md" p="lg">
      <Bowl
        enableHrefLang={false}
        language="en"
        title={LegalDocumentType[type].title}
      />

      <Stack gap="lg">
        <Anchor href={AppRouteURL.about} underline="hover">
          <Group gap="xs">
            <IconArrowBackUp />
            {t('labels.back')}
          </Group>
        </Anchor>

        <Title order={1}>
          {PRODUCT_NAME} • {LegalDocumentType[type].title}
        </Title>

        <TermlyEmbed termlyId={LegalDocumentType[type].termlyId} />
      </Stack>
    </Container>
  );
};

export default LegalDocumentPage;
