import {
  Anchor,
  Button,
  Loader,
  Modal,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconRosetteDiscountCheck, IconTransfer } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Logger } from 'tslog';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { IAnalysis } from '../../models/Analysis';
import { QUERY_KEY_PROFILE } from '../../models/ProfileQueries';
import Tracking from '../../services/Tracking';

interface ImportProfileModalProps {
  opened: boolean;
  onClose: () => void;
}

const logger = new Logger({ name: 'ImportProfileModal' });

export const QUERY_KEY_ANALYSIS = ['analysis'];

export const ImportProfileModal: React.FC<ImportProfileModalProps> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [importing, setImporting] = useState(false);

  const { data: analysis, refetch: refetchAnalysis } = useQuery({
    queryKey: QUERY_KEY_ANALYSIS,
    queryFn: () => Api.ky.get(ApiRoutes.Analysis).json<IAnalysis>(),
  });

  const [success, setSuccess] = useState(analysis?.status === 'imported');

  const mutation = useMutation({
    mutationFn: () => Api.ky.post(ApiRoutes.AnalysisImport).json(),
    onSuccess: () => refetchAnalysis(),
    onError: () => {
      showNotification({
        title: t('importProfileModal.errorTitle'),
        message: t('importProfileModal.errorMessage'),
        color: 'red',
      });
      setImporting(false);
    },
  });

  const handleConfirm = () => {
    setImporting(true);
    setSuccess(false);
    Tracking.event({ event: 'import_from_analysis' });
    mutation.mutate();
  };

  const handleSuccess = () => {
    navigate(AppRouteURL.resumes.create);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const checkAnalysisImport = () => {
      if (importing) {
        if (analysis?.status === 'imported') {
          setImporting(false);
          setSuccess(true);
          queryClient
            .invalidateQueries({ queryKey: QUERY_KEY_PROFILE })
            .catch(console.error);
        } else if (analysis?.status === 'error') {
          setImporting(false);
          setSuccess(false);
        } else {
          refetchAnalysis().catch((err) => logger.error(err));
          timeout = setTimeout(checkAnalysisImport, 2000);
        }
      }
    };

    if (importing) {
      timeout = setTimeout(checkAnalysisImport, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [importing, refetchAnalysis, analysis?.status]);

  const handleClose = () => {
    setSuccess(false);
    setImporting(false);
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={
        <Text fw="bold" fz="1.5rem">
          {t('importProfileModal.title')}
        </Text>
      }
      size="lg"
      centered
    >
      <Stack gap="md">
        {importing ? (
          <Stack gap="xl" m="xl" align="center">
            <Loader size="xl" variant="bars" />
            <Title order={3}>{t('importProfileModal.importing')}</Title>
          </Stack>
        ) : success ? (
          <Stack gap="xl" m="xl" align="center">
            <IconRosetteDiscountCheck size={120} stroke={1} color="green" />
            <Title order={3} ta="center">
              {t('importProfileModal.success')}
            </Title>
            <Button variant="filled" color="blue" onClick={handleSuccess}>
              {t('labels.close')}
            </Button>
          </Stack>
        ) : (
          <>
            <Stack gap="xl" m="xl" align="center">
              <ThemeIcon size={120} variant="transparent">
                <IconTransfer size={120} stroke={1} />
              </ThemeIcon>
              <Text ta="center">{t('importProfileModal.description')}</Text>
              <Text ta="center">{t('importProfileModal.warning')}</Text>
              <Button
                variant="filled"
                color="blue"
                leftSection={<IconTransfer />}
                onClick={handleConfirm}
                size="lg"
                radius="xl"
              >
                {t('importProfileModal.confirm')}
              </Button>
            </Stack>
            <Anchor variant="default" onClick={handleClose}>
              {t('importProfileModal.cancel')}
            </Anchor>
          </>
        )}
      </Stack>
    </Modal>
  );
};
