import { Anchor, Button, Stack, Text } from '@mantine/core';
import { IconStar } from '@tabler/icons-react';
import { t } from 'i18next';
import React from 'react';
import { STRIPE_BILLING_PORTAL_URL } from '../../Config';
import { SubscriptionType, useUserQuery } from '../../models/User';
import { getLogger } from '../../services/Logger';
import Platform from '../../services/Platform';

const APPLE_SUBSCRIPTION_MANAGEMENT_URL =
  'https://apps.apple.com/account/billing';

const log = getLogger('SubscribeButton');

const SubscribeButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { data: user } = useUserQuery();

  let manageSubscriptionLink: React.ReactNode = null;

  if (user && user.subscriptionExpiresAt !== null) {
    if (user.paymentProvider === 'stripe') {
      manageSubscriptionLink = (
        <Anchor href={STRIPE_BILLING_PORTAL_URL} target="_blank" ta="center">
          {t('account.manageSubscription')}
        </Anchor>
      );
    } else if (user.paymentProvider === 'apple') {
      if (Platform.iosPwa) {
        manageSubscriptionLink = (
          <Anchor
            onClick={() => Platform.openSubscriptionManagement()}
            ta="center"
          >
            {t('account.manageSubscription')}
          </Anchor>
        );
      } else {
        // If a user has an Apple subscription, we can't provide a link to manage it
        manageSubscriptionLink = (
          <Anchor
            href={APPLE_SUBSCRIPTION_MANAGEMENT_URL}
            target="_blank"
            ta="center"
          >
            {t('account.manageSubscription')}
          </Anchor>
        );
      }
    } else if (user.paymentProvider) {
      log.warn(`Unknown payment provider ${user.paymentProvider}`);
      manageSubscriptionLink = <Text>{t('account.manageSubscription')}</Text>;
    }
  }

  const button = (
    <Button
      size="md"
      mx="xl"
      variant="filled"
      leftSection={<IconStar />}
      onClick={onClick}
    >
      {t('account.subscribeButtonCTA')}
    </Button>
  );

  return (
    <Stack mt="md" gap="lg">
      {user?.subscriptionType !== SubscriptionType.PRO && button}
      {manageSubscriptionLink}
    </Stack>
  );
};

export default SubscribeButton;
