import {
  ActionIcon,
  Button,
  Center,
  Group,
  Stack,
  Text,
  Timeline,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBuildingSkyscraper,
  IconNewSection,
  IconPencil,
  IconPlaylistAdd,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WhatIsThis from '../../components/WhatIsThis/WhatIsThis';
import { PRODUCT_NAME } from '../../Constants';
import { IUserProfileSection } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { MultilineText } from './MultilineText';
import { TimelineModal } from './TimelineModal';

import classes from './TimelineTab.module.css';

export const TimelineTab: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { data: profile, isPending } = useProfileQuery();
  const [modalOpened, setModalOpened] = useState(false);
  const [currentSection, setCurrentSection] =
    useState<IUserProfileSection | null>(null);
  const [lastEditedSection, setLastEditedSection] =
    useState<IUserProfileSection | null>(null);

  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const dateFormat = isSmallScreen ? 'MM/YYYY' : 'MMMM YYYY';

  const sections = useMemo(() => {
    if (profile?.sections) {
      return profile.sections.sort((a, b) => {
        const fromDiff = dayjs(b.from || 0).diff(dayjs(a.from || 0));
        const toDiff = dayjs(b.to || '31-12-2099').diff(
          dayjs(a.to || '31-12-2099'),
        );
        return fromDiff || toDiff;
      });
    }
    return [];
  }, [profile?.sections]);

  const handleEdit = (section: IUserProfileSection) => {
    setLastEditedSection(null);
    setCurrentSection(section);
    setModalOpened(true);
  };

  const handleClose = () => {
    setLastEditedSection(currentSection);
    setCurrentSection(null);
    setModalOpened(false);
  };

  const handleAdd = () => {
    setCurrentSection({
      title: '',
      company: '',
      from: '',
      to: '',
      description: '',
    } as IUserProfileSection);
    setModalOpened(true);
  };

  if (isPending) {
    return <Text>{t('labels.loading')}</Text>;
  }

  return (
    <Stack my="lg" gap="xl" pb="lg">
      <WhatIsThis
        message={t('profile.timelineHelp', { brand: PRODUCT_NAME })}
      />

      {(!sections || sections.length === 0) && (
        <Center mt="xl">
          <Text size="lg">{t('profile.noTimeline')}</Text>
        </Center>
      )}

      <Timeline active={sections?.length} lineWidth={2} bulletSize={24}>
        {sections?.map((section, index) => (
          <Timeline.Item
            key={section.id ?? index}
            lineVariant={index + 1 === sections?.length ? 'dashed' : 'solid'}
            bullet={<IconBuildingSkyscraper size={12} />}
            className={
              section.id === lastEditedSection?.id ? classes.flashSection : ''
            }
            onClick={() => handleEdit(section)}
            title={
              <Group justify="space-between" wrap="nowrap">
                <Text fw="bold" fz="lg">
                  {section.title ?? t('timeline.titlePlaceholder')}
                </Text>
                <ActionIcon
                  variant="subtle"
                  onClick={() => handleEdit(section)}
                  size="md"
                  disabled={disabled}
                >
                  <IconPencil />
                </ActionIcon>
              </Group>
            }
            pb="xs"
          >
            <Stack mb="md" gap="xs" className={classes.timelineSection}>
              <Group justify="space-between" wrap="nowrap">
                <Text fs="italic">
                  {section.company ?? t('timeline.companyPlaceholder')}
                </Text>
                <Text fs="italic">
                  {section.from
                    ? dayjs(section.from).format(dateFormat)
                    : t('timeline.startDatePlaceholder')}{' '}
                  -{' '}
                  {section.to
                    ? dayjs(section.to).format(dateFormat)
                    : t('timeline.present')}
                </Text>
              </Group>
              <MultilineText
                text={
                  section.description ?? t('timeline.descriptionPlaceholder')
                }
              />
            </Stack>
          </Timeline.Item>
        ))}
        <Timeline.Item bullet={<IconNewSection size={12} />}>
          <Button
            size="xs"
            leftSection={<IconPlaylistAdd />}
            onClick={handleAdd}
            disabled={disabled}
          >
            {t('timeline.addSectionLabel')}
          </Button>
        </Timeline.Item>
      </Timeline>

      <TimelineModal
        opened={modalOpened}
        onClose={handleClose}
        section={currentSection}
        sections={sections}
        profile={profile}
      />
    </Stack>
  );
};
