import { Text, TextProps } from '@mantine/core';
import React from 'react';

export const MultilineText: React.FC<{ text: string } & TextProps> = ({
  text,
  ...props
}) => {
  return (
    <>
      {text.split('\n').map((line, index) => (
        <Text key={index} {...props}>
          {line}
        </Text>
      ))}
    </>
  );
};
