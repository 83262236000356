import { Alert, Button, PasswordInput, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconKey } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';

import { Form, FormikProvider, useFormik } from 'formik';
import { HTTPError } from 'ky';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Api, { IApiError } from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { IUser } from '../../models/User';

export const ResetPasswordForm: React.FC<{
  id: IUser['id'];
  token: string;
}> = ({ id, token }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();

  const { t } = useTranslation();

  const ResetPasswordSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(6, t('translation:errors.minLength', { length: 6 }))
          .required(t('translation:errors.required')),
        passwordConfirmation: Yup.string()
          .nullable()
          .equals(
            [Yup.ref('password')],
            t('translation:errors.passwordsMustMatch'),
          ),
      }),
    [],
  );

  const mutation = useMutation({
    mutationFn: (values: { id: string; token: string; password: string }) =>
      Api.ky.put(ApiRoutes.ResetPassword, { json: values }),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async ({ password }, actions) => {
      try {
        await mutation.mutateAsync({ id, token, password });
        showNotification({
          title: t('translation:resetPassword.successTitle'),
          icon: <IconCheck />,
          message: t('translation:resetPassword.successText'),
        });
        navigate(AppRouteURL.home);
        navigate(0);
      } catch (error) {
        actions.setStatus(error);
        actions.setSubmitting(false);

        console.dir(error);

        if (error instanceof HTTPError) {
          setError(((await error.response.json()) as IApiError).message);
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Stack>
            {error && (
              <Alert color="red" title="Error" icon={<IconAlertTriangle />}>
                {error}
              </Alert>
            )}
            <PasswordInput
              placeholder={t('translation:resetPassword.passwordPlaceholder')}
              label={t('translation:resetPassword.passwordLabel')}
              {...getFieldProps('password')}
              error={
                touched.password && errors.password ? errors.password : null
              }
            />
            <PasswordInput
              placeholder={t(
                'translation:resetPassword.passwordConfirmationPlaceholder',
              )}
              label={t('translation:resetPassword.passwordConfirmationLabel')}
              {...getFieldProps('passwordConfirmation')}
              error={
                touched.passwordConfirmation && errors.passwordConfirmation
                  ? t('errors.passwordConfirmation')
                  : null
              }
            />
          </Stack>

          <Button
            fullWidth
            size="lg"
            type="submit"
            variant="filled"
            loading={isSubmitting}
            leftSection={<IconKey />}
          >
            {isSubmitting
              ? t('translation:resetPassword.submittingButton')
              : t('translation:resetPassword.submitButton')}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
