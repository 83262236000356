import { Group, Menu, Switch, useMantineColorScheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAlien,
  IconExclamationCircle,
  IconLanguage,
  IconMoonStars,
  IconSun,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { VERSION } from '../../Config';
import { getLogger } from '../../services/Logger';
import { LanguageSwitcherModal } from '../LanguageSwitcherModal/LanguageSwitcherModal';

const log = getLogger('UserMenuDropdown');

export const UserMenuDropdown: React.FC = () => {
  const { t } = useTranslation();
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { signout } = useAuth();
  const queryClient = useQueryClient();
  const [languageModalOpen, { open, close }] = useDisclosure(false);

  return (
    <>
      <Menu.Dropdown>
        <Menu.Label>
          <Group justify="space-between">
            <span>{t('userMenu.user')}</span>
            <span>{VERSION.substring(0, 6)}</span>
          </Group>
        </Menu.Label>
        <Menu.Item
          component={Link}
          to={AppRouteURL.account}
          leftSection={<IconUser size={14} />}
        >
          {t('userMenu.account')}
        </Menu.Item>

        <Menu.Item leftSection={<IconLanguage size={14} />} onClick={open}>
          {t('userMenu.changeLanguage')}
        </Menu.Item>

        <Menu.Item
          leftSection={
            colorScheme === 'dark' ? (
              <IconSun size={14} />
            ) : (
              <IconMoonStars size={14} />
            )
          }
        >
          <Switch
            label={t('userMenu.darkMode')}
            checked={colorScheme === 'dark'}
            labelPosition="left"
            onChange={(event) =>
              setColorScheme(event.currentTarget.checked ? 'dark' : 'light')
            }
          />
        </Menu.Item>

        <Menu.Item
          component={Link}
          to={AppRouteURL.feedback}
          leftSection={<IconExclamationCircle size={14} />}
        >
          {t('userMenu.feedback')}
        </Menu.Item>

        <Menu.Item
          component={Link}
          to={AppRouteURL.about}
          leftSection={<IconAlien size={14} />}
        >
          {t('userMenu.aboutUs')}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          color="red"
          leftSection={<IconTrash size={14} />}
          onClick={() =>
            signout()
              .then(() => queryClient.resetQueries())
              .then(() => navigate(AppRouteURL.login))
              .catch((err) => log.error(err))
          }
        >
          {t('userMenu.logout')}
        </Menu.Item>
      </Menu.Dropdown>
      <LanguageSwitcherModal opened={languageModalOpen} onClose={close} />
    </>
  );
};
