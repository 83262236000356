import {
  Divider,
  Grid,
  Paper,
  Rating,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconLanguage } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserLanguage } from '../../models/Profile';
import { LanguagesModal } from './LanguagesModal';
import { TitleWithEditButton } from './TitleWithEditButton';

const LanguageEntry: React.FC<{ language: IUserLanguage }> = ({ language }) => {
  const { t } = useTranslation();

  const skillLevels = [
    { value: '0', label: t('skills.basic') },
    { value: '1', label: t('skills.advanced') },
    { value: '2', label: t('skills.professional') },
    { value: '3', label: t('skills.native') },
  ] as const;

  return (
    <>
      <Grid.Col span={12}></Grid.Col>
      <Grid.Col span={8}>
        <Stack gap={'0.1rem'}>
          <Text>{language.name}</Text>
          <Text size="sm">
            {skillLevels.find((l) => language.level === l.value)?.label || ''}
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={4}>
        <Tooltip
          events={{ hover: true, focus: true, touch: true }}
          label={
            skillLevels.find((l) => language.level === l.value)?.label || ''
          }
        >
          <div>
            <Rating count={5} value={Number(language.level) + 2} readOnly />
          </div>
        </Tooltip>
      </Grid.Col>
    </>
  );
};

export const ProfileLanguageSection: React.FC<{
  languages?: IUserLanguage[];
  disabled?: boolean;
}> = ({ languages = [], disabled = false }) => {
  const { t } = useTranslation();
  const [languagesModalOpen, setLanguagesModalOpen] = useState(false);

  const sortedLanguages = useMemo(
    () =>
      languages.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [languages],
  );

  return (
    <Paper p="sm" shadow="sm">
      <TitleWithEditButton
        icon={<IconLanguage />}
        title={t('profile.languages')}
        onClick={() => setLanguagesModalOpen(true)}
        disabled={disabled}
      />
      <Divider mt="xs" mb="md" />

      {languages.length > 0 ? (
        <Grid
          onClick={() => setLanguagesModalOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          {sortedLanguages.map((language, index) => (
            <LanguageEntry key={language.id || index} language={language} />
          ))}
        </Grid>
      ) : (
        <Text c="dimmed" fs="italic">
          {t('profile.noLanguages')}
        </Text>
      )}
      <LanguagesModal
        opened={languagesModalOpen}
        onClose={() => setLanguagesModalOpen(false)}
      />
    </Paper>
  );
};
