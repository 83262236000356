import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

import App from './App';
import { ENVIRONMENT, IS_DEVELOPMENT, VERSION } from './Config';
import './i18n';
import './services/Platform';
import { getLogger } from './services/Logger';

const SW_UPDATE_INTERVAL = (IS_DEVELOPMENT ? 60 : 8 * 60 * 60) * 1000;

const log = getLogger('index');

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

if (IS_DEVELOPMENT) {
  log.warn('You are running a development build!');
} else {
  Sentry.init({
    dsn: 'https://45179e62b07744c0bd52793df8134cc5@o4504617022390272.ingest.sentry.io/4504617025404928',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: ENVIRONMENT,
    release: VERSION,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    log.info(`Service worker registered: ${swUrl}`);
    r &&
      setInterval(async () => {
        log.debug(`Checking for SW update`);

        if (!(!r.installing && navigator)) return;

        if ('connection' in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });

        if (resp?.status === 200) await r.update();
      }, SW_UPDATE_INTERVAL);
  },
  onNeedRefresh() {
    log.warn('Service worker needs to be refreshed');
  },
});
